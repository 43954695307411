export default class GlobalMenu {
  constructor() {
    this.$trigger = $('.c-globalNavi__trigger');
    this.$menu = $('.c-globalNavi__menu');
    this.$menuList = $('.c-globalNavi__menu a');
    this.$htmlWrap = $('html, body');
    this.isActive = false;
  }

  init() {
    this.$trigger.on('click', () => {
      if (!this.isActive) {
        this.isActive = true;
        this.show();
      } else {
        this.isActive = false;
        this.hide();
      }
    });

    this.$menuList.on('click', () => {
      this.isActive = false;
      this.hide();
    });

    this.$menu.on('animationend webkitAnimationEnd', () => {
      document.querySelector('.c-globalNavi__menu').scrollTo(0, 0);
    });
  }

  show() {
    this.$trigger.addClass('is-active');
    this.$menu.addClass('is-active');
    this.$htmlWrap.addClass('is-noScroll');
  }

  hide() {
    this.$trigger.removeClass('is-active');
    this.$menu.removeClass('is-active');
    this.$htmlWrap.removeClass('is-noScroll');
  }
}
