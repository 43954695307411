
export default class ScrollAnimationTrigger {

  constructor() {
    this.$triggers = $('.scroll--trigger');
    this.$document = $(document);
  }

  init(){
    this.$document.on('mousewheel scroll', (e)=> {
      this.done(this.getScrollTop());
    });

    this.done(this.getScrollTop());
  }

  done(scrollTop) {
    for (var i = 0; i < this.$triggers.length; i++) {
      if ($(this.$triggers[i]).offset().top <= (scrollTop + $(window).height() * 0.75)) {
          $(this.$triggers[i]).addClass('is-done');
      }
    }
  }

  getScrollTop(){
    return document.body.scrollTop || document.documentElement.scrollTop;
  }
}
