import GoogleMapsLoader from 'google-maps';

GoogleMapsLoader.KEY = 'AIzaSyB8bWN17HVLtw8r2GDLDDI_sWI3ZkC40lk';

export default class Map {
  constructor(mapOption, pinOption) {
    this.map = null;
    this.mapPin = null;
    this.lat = mapOption.lat;
    this.lng = mapOption.lng;
    this.googleObj = null;
    this.pinImg = pinOption.img;
    this.pinWidth = pinOption.width;
    this.pinHeight = pinOption.height;
    this.pinTitle = pinOption.title;
    this.target = document.getElementById(mapOption.target);

    GoogleMapsLoader.load(this.init.bind(this));
  }

  init(google) {
    this.map = new google.maps.Map(this.target, {
      zoom: 16,
      maxZoom: 19,
      minZoom: 4,
      center: new google.maps.LatLng(this.lat, this.lng),
      mapTypeControl: false,
      scaleControl: false,
      scrollwheel: false,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      zoomControl: true,
      zoomControlOptions: {
        style: google.maps.ZoomControlStyle.SMALL,
        position: google.maps.ControlPosition.TOP_LEFT
      },
      panControl: false
    });
    this.googleObj = google;

    this.setStyle();
    this.setPin();

    this.googleObj.maps.event.addDomListener(window, 'resize', () => {
      const center = this.map.getCenter();
      this.googleObj.maps.event.trigger(this.map, "resize");
      this.map.setCenter(center);
    });
  }

  bindResizeEven() {
    this.map.setCenter(new this.googleObj.maps.LatLng(this.lat, this.lng));
  }

  setStyle() {
    const styleOptions = [{
        "featureType": "administrative",
        "elementType": "labels.text.fill",
        "stylers": [{
          "color": "#444444"
        }]
      },
      {
        "featureType": "landscape",
        "elementType": "all",
        "stylers": [{
          "color": "#f2f2f2"
        }]
      },
      {
        "featureType": "poi",
        "elementType": "all",
        "stylers": [{
          "visibility": "off"
        }]
      },
      {
        "featureType": "poi",
        "elementType": "labels.text",
        "stylers": [{
          "visibility": "off"
        }]
      },
      {
        "featureType": "road",
        "elementType": "all",
        "stylers": [{
            "saturation": -100
          },
          {
            "lightness": 45
          }
        ]
      },
      {
        "featureType": "road.highway",
        "elementType": "all",
        "stylers": [{
          "visibility": "simplified"
        }]
      },
      {
        "featureType": "road.arterial",
        "elementType": "labels.icon",
        "stylers": [{
          "visibility": "off"
        }]
      },
      {
        "featureType": "transit",
        "elementType": "all",
        "stylers": [{
          "visibility": "off"
        }]
      },
      {
        "featureType": "water",
        "elementType": "all",
        "stylers": [{
            "color": "#dbdbdb"
          },
          {
            "visibility": "on"
          }
        ]
      }
    ];
    const styledMapOptions = {
      name: 'グレー'
    };

    const monoType = new this.googleObj.maps.StyledMapType(styleOptions, styledMapOptions);

    this.map.mapTypes.set('mono', monoType);
    this.map.setMapTypeId('mono');
  }

  setPin(i) {
    this.pin = new this.googleObj.maps.Marker({
      position: new this.googleObj.maps.LatLng(this.lat, this.lng),
      map: this.map
    });

    this.pin.setIcon({
      url: this.pinImg,
      scaledSize: new this.googleObj.maps.Size(this.pinWidth, this.pinHeight)
    });

    this.pin.setTitle(this.pinTitle);
  }

}
