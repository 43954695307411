import Barba from "barba.js";
import ScrollAnimationTrigger from "../modules/ScrollAnimationTrigger";
import GlobalFooterController from "../controller/GlobalFooterController.js";

export const antiqueChronologyView = Barba.BaseView.extend({
  namespace: 'antique-chronology',
  onEnter: () => {
    // The new Container is ready and attached to the DOM.
  },
  onEnterCompleted: () => {
    const scrollAnimationTrig = new ScrollAnimationTrigger();
    const globalFooterCtrl = new GlobalFooterController();
    scrollAnimationTrig.init();
    globalFooterCtrl.init();

    $.getJSON('/assets/json/chronology.json').done((data) => {

      $('a.c-history__txts--inner').on('click', (event) => {
        event.preventDefault();
        const $target = $(event.currentTarget);
        const id = $target.data('id');
        const item = data[id];
        $('.c-modal--history').fadeIn().css('display', 'flex');

        $('.c-modal--history__container__hgroup__num').html(item['year']);
        $('.c-modal--history__container__hgroup__main').html(item['title']);
        $('.c-modal--history__container__hgroup__bress').html('{ ' + item['bress'] + ' }');
        $('.c-modal--history__container__content p').html(item['txt']);
      })

      $('.c-modal--history__container__close').on('click', (event) => {
        $('.c-modal--history').fadeOut();
      });
    });
  },
  onLeave: () => {
    // A new Transition toward a new page has just started.
  },
  onLeaveCompleted: () => {
    // The Container has just been removed from the DOM.
  }
});
