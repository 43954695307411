
export default class GlobalFooterController {

  constructor() {
    this.$pageTop = $('.c-globalFooter__foot__pageTop');
    this.$htmlWrap = $('body, html');
  }

  init(){
    this.$pageTop.on('click', ()=>{
      this.$htmlWrap.animate({scrollTop: 0}, 500);
    })
  }
}
