import Barba from "barba.js";
import ScrollAnimationTrigger from "../modules/ScrollAnimationTrigger";
import GlobalFooterController from "../controller/GlobalFooterController.js";

export const homeView = Barba.BaseView.extend({
  namespace: "home",
  onEnter: () => {
    // The new Container is ready and attached to the DOM.
  },
  onEnterCompleted: () => {
    const scrollAnimationTrig = new ScrollAnimationTrigger();
    const globalFooterCtrl = new GlobalFooterController();
    scrollAnimationTrig.init();
    globalFooterCtrl.init();

    // メインエリアの文字数が画像の高さを超えた時の処理
//    const mql = window.matchMedia('screen and (max-width: 1039px)');
//    mql.addListener(checkBreakPoint);
//
//    function checkBreakPoint(mql) {
//      let mainTxtsHeight = $('.p-home__main__contents__txts').height();
//      let mainImgHeight = $('.p-home__main__contents__thumbs--pc').height();
//      const $container = $('.p-home__container');
//
//
//
//      if (mql.matches) {
//        $container.removeAttr('style');
//
//        window.addEventListener('resize', bindReizeWindow);
//
//      } else {
//        if (mainTxtsHeight > mainImgHeight) {
//          $container.css('padding-top', 160 + (mainTxtsHeight - mainImgHeight));
//        }
//
//        window.removeEventListener('resize', bindReizeWindow);
//      }
//    }
//
//    function bindReizeWindow() {
//      let windowWidth = $(window).width();
//      $('.p-home__main__contents__txts__thumbs--sp').width(windowWidth);
//    }
//
//    checkBreakPoint(mql);

    // スマホ時に開閉用の非表示Wrapを追加（ロード時）
    if ( window.matchMedia( '(max-width: 1093px)' ).matches ) {
      if ( $('.js-addWrap').length == 0 && $('.c-list').length > 5 ) {
        $('.c-list:gt(4)').wrapAll('<li class="js-addWrap"><ul class="js-addWrap_child"></ul></li>');
      }
    } else {
      $('.js-addWrap_child > li').unwrap();
      $('.js-addWrap > li').unwrap();
    }

    // スマホ時に開閉用の非表示Wrapを追加
    $(window).on('resize', function() {

      if ( window.matchMedia( '(max-width: 1093px)' ).matches ) {
        if ( $('.js-addWrap').length == 0 && $('.c-list').length > 5 ) {
          $('.c-list:gt(4)').wrapAll('<li class="js-addWrap"><ul class="js-addWrap_child"></ul></li>');
        }
      } else {
        $('.js-addWrap_child > li').unwrap();
        $('.js-addWrap > li').unwrap();
      }

    });

    // コーディネート、商品一覧の開閉
    var btn_text = $('.p-home__main__contents__txts__more').text();
    $(document).off('click', '.p-home__main__contents__txts__more');
    $(document).on('click', '.p-home__main__contents__txts__more', function() {
      $('.js-addWrap').slideToggle();
      $(this).toggleClass('is-open');
      if ( btn_text == 'More' ) {
        $(this).text("Close");
        btn_text = 'Close';
      } else {
        $(this).text("More");
        btn_text = 'More';
      }
    });

  },
  onLeave: () => {
    // A new Transition toward a new page has just started.
  },
  onLeaveCompleted: () => {
    // The Container has just been removed from the DOM.
  }
});
