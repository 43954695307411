export default class AnchorLinksController {

  constructor() {
    this.$anchorLinks = $('.c-anchorLinks');
    this.$linkElement = $('.c-anchorLinks a');
    this.$body_html = $('body,html');
    this.scrollSpeed = 400;
  }

  init() {
    if (this.$anchorLinks.length > 0) {
      this.$linkElement.on('click', this.onScroll.bind(this));
    }
  }

  onScroll(event) {
    event.preventDefault();

    const href = $(event.currentTarget).attr('href');
    const $target = $(href == '#' || href == '' ? 'html' : href);
    const scrollPos = $target.offset().top - 50;

    this.$body_html.animate({
      scrollTop: scrollPos
    }, this.scrollSpeed, 'swing');
  }
}
