import Barba from 'barba.js';
import $ from 'jquery';
window.$ = $;
window.jQuery = $;


import GlobalMenuController from './controller/GlobalMenuController';

import {
  homeView
}
from './pages/homeView';

import {
  stockDetailView
}
from './pages/stockDetailView';

import {
  designWorkOrdertableView
}
from './pages/designWorkOrdertableView';

import {
  leaseView
}
from './pages/leaseView';

import {
  guideDeliveryView
}
from './pages/guideDeliveryView';

import {
  guideFaqView
}
from './pages/guideFaqView';

import {
  guideLightingView
}
from './pages/guideLightingView';

import {
  accessView
}
from './pages/accessView';

import {
  antiqueChronologyView
}
from './pages/antiqueChronologyView';

import {
  coordinateDetailView
}
from './pages/coordinateDetailView';

import {
  commonView
}
from './pages/commonView';


import FontFaceObserver from 'fontfaceobserver';
import ImagesLoaded from 'imagesloaded';

const fontObservers = [
  new FontFaceObserver('Scotch Roman MT W01_706948'),
  new FontFaceObserver('AdobeCaslonW01-SmBdIt'),
  new FontFaceObserver('AdobeCaslonW01-Regular'),
  new FontFaceObserver('Playfair Display')
];

Promise.all(fontObservers).then(() => {
  imgLoad.on('done', instance => {
    $('html').addClass('is-active');
    $('html').removeClass('is-loading');
  });
});

const imgLoad = ImagesLoaded(document.querySelector('#barba-wrapper'));
const globalMenuCtrl = new GlobalMenuController();
globalMenuCtrl.init();

const fadeTransition = Barba.BaseTransition.extend({
  start: function () {
    Promise.all([this.newContainerLoading, this.fadeOut()]).then(this.fadeIn.bind(this));
  },

  fadeOut: function () {
    return $(this.oldContainer)
      .animate({
        opacity: 0
      }, 500)
      .promise();
  },

  fadeIn: function () {
    var _this = this;
    var $el = $(this.newContainer);
    window.scrollTo(0, 0);

    $(this.oldContainer).hide();

    $el.css({
      visibility: 'visible',
      opacity: 0
    });

    $el.animate({
      opacity: 1
    }, 400, function () {
      _this.done();
    });
  }
});


Barba.Pjax.getTransition = function () {
  return fadeTransition;
};

/*
*
* Dispatch Gtag Event
*
*/
Barba.Dispatcher.on('initStateChange', function() {
  // if (typeof ga === 'function' && Barba.HistoryManager.history.length >= 1) {
  //   ga('send', 'pageview', location.pathname);
  // }
  // if (typeof gtag === 'function' && Barba.HistoryManager.history.length >= 1) {
  //   gtag('config', 'GTM-WKH3GKK', {'page_path': location.pathname});
  // }
});

/*
*
* Change Page Event
*
*/
Barba.Dispatcher.on('newPageReady', (currentStatus, oldStatus, container, newPageRawHTML) => {
  const namespace = $(container).data('namespace');

  // Google Tag Manager: set pageview
  let trackPageURL = location.pathname;
  if(location.search !== '') trackPageURL += location.search;

  dataLayer.push({
  'trackPageview': trackPageURL,
  'event': 'loadready'
  });


  // if (typeof ga === 'function' && Barba.HistoryManager.history.length >= 1) {
  //   ga('send', 'pageview', location.pathname);
  //   ga('send', 'pageview', 'ページビューのイベントを送るテスト', location.pathname)


  //   console.log('typeof ga');
  //   console.log(location.pathname);
  // }
  // if (typeof gtag === 'function' && Barba.HistoryManager.history.length >= 1) {
  //   gtag('config', 'GTM-WKH3GKK', {'page_path': location.pathname});

  //   console.log('typeof gtag');
  //   console.log(location.pathname);
  // }

  // Meta書き換え
  const head = document.head;
  const newPageRawHead = newPageRawHTML.match( /<head[^>]*>([\s\S.]*)<\/head>/i )[ 0 ];
  const newPageHead = document.createElement('head');
  newPageHead.innerHTML = newPageRawHead;
  const headTags = [
        "meta[name='keywords']",
        "meta[name='description']",
        "meta[property^='og']",
        "meta[name^='twitter']",
        "meta[itemprop]",
        "link[itemprop]",
        "link[rel='prev']",
        "link[rel='next']",
        "link[rel='canonical']",
        "link[rel='alternate']"
    ].join(',');

  const oldHeadTags = head.querySelectorAll( headTags );
  for ( let i = 0; i < oldHeadTags.length; i++ ) {
    head.removeChild( oldHeadTags[ i ] );
  }

  const newHeadTags = newPageHead.querySelectorAll( headTags );
  for ( let i = 0; i < newHeadTags.length; i++ ) {
    head.appendChild( newHeadTags[ i ] );
  }

  switch (namespace) {
    case 'home':
      homeView.init();
      break;
    case 'stock-detail':
      stockDetailView.init();
      break;

    case 'design-work-ordertable':
      designWorkOrdertableView.init();
      break;

    case 'lease':
      leaseView.init();
      break;

    case 'guide-delivery':
      guideDeliveryView.init();
      break;

    case 'guide-faq':
      guideFaqView.init();
      break;

    case 'guide-lighting':
      guideLightingView.init();
      break;

    case 'access':
      accessView.init();
      break;

    case 'antique-chronology':
      antiqueChronologyView.init();
      break;

    case 'coordinate-detail':
      coordinateDetailView.init();
      break;

    default:
      commonView.init();
      break;
  }

  // ページ読み込み時の位置初期化
  const urlHash = location.hash;
  const $body_html = $('body,html');

  if (urlHash) {
    const $target = $(urlHash);
    const scrollPos = $target.offset().top - 60;
    $body_html.animate({
      scrollTop: scrollPos
    }, 400, 'swing');
  }

  // スマホ用hover処理追加
  $('a, input[type="button"], input[type="submit"], button')
    .on('touchstart', event => {
      $(event.currentTarget).addClass('hover');
    })
    .on('touchend', function (event) {
      $(event.currentTarget).removeClass('hover');
    });
});


Barba.Pjax.init();
Barba.Prefetch.init();
