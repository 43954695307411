import Barba from 'barba.js';
import ScrollAnimationTrigger from '../modules/ScrollAnimationTrigger';
import GlobalFooterController from '../controller/GlobalFooterController.js';
import AnchorLinksController from '../controller/AnchorLinksController.js';
import Map from '../modules/Map';

export const accessView = Barba.BaseView.extend({
  namespace: 'access',
  onEnter: () => {
    // The new Container is ready and attached to the DOM.
  },
  onEnterCompleted: () => {
    const scrollAnimationTrig = new ScrollAnimationTrigger();
    const globalFooterCtrl = new GlobalFooterController();
    const anchorLinksCtrl = new AnchorLinksController();

    scrollAnimationTrig.init();
    globalFooterCtrl.init();
    anchorLinksCtrl.init();


    const yokohamaMap = new Map({
      target: 'googlemap--yokohama',
      lat: 35.442171,
      lng: 139.649585
    }, {
      img: '/assets/images/access/ico-pin.png',
      width: 272 / 1.5,
      height: 61 / 1.5,
      title: 'Yokohama'
    });

    const fujisawaMap = new Map({
      target: 'googlemap--fujisawa',
      lat: 35.379832,
      lng: 139.449789
    }, {
      img: '/assets/images/access/ico-pin.png',
      width: 272 / 1.5,
      height: 61 / 1.5,
      title: 'Fujisawa'
    });


  },
  onLeave: () => {
    // A new Transition toward a new page has just started.
  },
  onLeaveCompleted: () => {
    // The Container has just been removed from the DOM.
  }
});
