import Barba from 'barba.js';
import ScrollAnimationTrigger from '../modules/ScrollAnimationTrigger';
import GlobalFooterController from '../controller/GlobalFooterController.js';

export const guideDeliveryView = Barba.BaseView.extend({
  namespace: 'guide-delivery',
  onEnter: () => {
    // The new Container is ready and attached to the DOM.
  },
  onEnterCompleted: () => {
    const scrollAnimationTrig = new ScrollAnimationTrigger();
    const globalFooterCtrl = new GlobalFooterController();
    scrollAnimationTrig.init();
    globalFooterCtrl.init();

    $.when(
        $.getJSON('/assets/json/you_pack.json'),
        $.getJSON('/assets/json/yamato.json')
      )
      .done((you_pack, yamato) => {
        $('.yamato--title').parent().hide();
        $('.you_pack--title').parent().hide();

        $('[name=you_pack]').append(getSelectBox(you_pack[0]));
        $('[name=yamato]').append(getSelectBox(yamato[0]));
        $('[name=you_pack]').on('change', onChangeSelectbox.bind(this, you_pack[0], 'you_pack'));
        $('[name=yamato]').on('change', onChangeSelectbox.bind(this, yamato[0], 'yamato'));
      })
      .fail(() => {
        console.log('error');
      });

    function onChangeSelectbox(data, targetKey, event) {
      const target = targetKey;
      const val = $('[name=' + target + ']').val();
      const label = $('[name=' + target + ']' + ' :selected').text();
      const keys = data['keys'];
      const values = data['pref'][label];

      if (val === '') return;
      let html = '<tr>';
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        html += '<th>' + key + '</th>';
      }
      html += '</tr><tr>';

      for (let i = 0; i < values.length; i++) {
        const value = values[i];
        let text = '';

        if (value !== '') text = '¥' + String(value).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
        html += '<td>' + text + '</td>';
      }
      html += '</tr>';

      $('.' + target + '--info').show();
      $('.' + target + '--title').parent().show();
      $('.' + target + '--title').empty().append(label + 'への配送料');
      $('.' + target + '--table').empty().append(html);
    }

    function getSelectBox(data) {
      let html = '';
      let incrementNum = 0;
      for (const key in data['pref']) {
        if (data['pref'].hasOwnProperty(key)) {
          html += '<option value="' + incrementNum + '">' + key + '</option>';
          incrementNum++;
        }
      }
      return html;
    }


  },
  onLeave: () => {
    // A new Transition toward a new page has just started.
  },
  onLeaveCompleted: () => {
    // The Container has just been removed from the DOM.
  }
});
