import Barba from 'barba.js';
import ScrollAnimationTrigger from '../modules/ScrollAnimationTrigger';
import GlobalFooterController from '../controller/GlobalFooterController.js';
import AnchorLinksController from '../controller/AnchorLinksController.js';

export const commonView = Barba.BaseView.extend({
  namespace: 'common',
  onEnter: () => {
    // The new Container is ready and attached to the DOM.
  },
  onEnterCompleted: () => {
    const scrollAnimationTrig = new ScrollAnimationTrigger();
    const globalFooterCtrl = new GlobalFooterController();
    const anchorLinksCtrl = new AnchorLinksController();

    scrollAnimationTrig.init();
    globalFooterCtrl.init();
    anchorLinksCtrl.init();
  },
  onLeave: () => {
    // A new Transition toward a new page has just started.
  },
  onLeaveCompleted: () => {
    // The Container has just been removed from the DOM.
  }
});
