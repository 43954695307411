import Barba from 'barba.js';
import ScrollAnimationTrigger from '../modules/ScrollAnimationTrigger';
import GlobalFooterController from '../controller/GlobalFooterController.js';

export const guideFaqView = Barba.BaseView.extend({
  namespace: 'guide-faq',
  onEnter: () => {
    // The new Container is ready and attached to the DOM.
  },
  onEnterCompleted: () => {
    const scrollAnimationTrig = new ScrollAnimationTrigger();
    const globalFooterCtrl = new GlobalFooterController();
    scrollAnimationTrig.init();
    globalFooterCtrl.init();

    $('.p-guide__faq dt').off('click');
    $('.p-guide__faq dt').on('click', function () {
      $(this).next().slideToggle();
      $(this).toggleClass('is-active');
    });
  },
  onLeave: () => {
    // A new Transition toward a new page has just started.
  },
  onLeaveCompleted: () => {
    // The Container has just been removed from the DOM.
  }
});
