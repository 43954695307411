import Barba from 'barba.js';
import ScrollAnimationTrigger from '../modules/ScrollAnimationTrigger';
import GlobalFooterController from '../controller/GlobalFooterController.js';
import Swiper from 'swiper';

export const leaseView = Barba.BaseView.extend({
  namespace: 'lease',

  onEnter: () => {
    // The new Container is ready and attached to the DOM.
  },
  onEnterCompleted: () => {
    const scrollAnimationTrig = new ScrollAnimationTrigger();
    const globalFooterCtrl = new GlobalFooterController();
    scrollAnimationTrig.init();
    globalFooterCtrl.init();

    console.log('leas!!!');

    const galleryMain = new Swiper('.p-lease__gallerys__gallery', {

      loop: true,
      pagination: {
        el: '.swiper-pagination',
      }
    });
  },
  onLeave: () => {
    // A new Transition toward a new page has just started.
  },
  onLeaveCompleted: () => {
    // The Container has just been removed from the DOM.
  }
});
