import Barba from 'barba.js';
import ScrollAnimationTrigger from '../modules/ScrollAnimationTrigger';
import GlobalFooterController from '../controller/GlobalFooterController.js';
import Swiper from 'swiper';

export const stockDetailView = Barba.BaseView.extend({
  namespace: 'stock-detail',
  onEnter: () => {
    // The new Container is ready and attached to the DOM.
  },
  onEnterCompleted: () => {
    const scrollAnimationTrig = new ScrollAnimationTrigger();
    const globalFooterCtrl = new GlobalFooterController();
    scrollAnimationTrig.init();
    globalFooterCtrl.init();

    const galleryMain = new Swiper('.p-stock--detail__contents__visuals__main', {
      loop: false,
      effect: 'fade'
    });

    setThumbnail(0);
    galleryMain.slideTo(0);

    $('.p-stock--detail__contents__visuals__thumbs span').on('click', (event) => {
      const $this = $(event.currentTarget);
      const slideNum = $this.data('number');

      galleryMain.slideTo(slideNum);

    });

    galleryMain.on('slideChange', function () {
      let slideNum = galleryMain.activeIndex;
      setThumbnail(slideNum);
    });

    function setThumbnail(slideNum) {
      $('.p-stock--detail__contents__visuals__thumbs *').removeClass('is-active');
      $('.p-stock--detail__contents__visuals__thumbs span:nth-child(' + (slideNum + 1) + ')').addClass('is-active');
    }

  },
  onLeave: () => {
    // A new Transition toward a new page has just started.
  },
  onLeaveCompleted: () => {
    // The Container has just been removed from the DOM.
  }
});
